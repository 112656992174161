<template>
<div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn  label="成交日期" prop="deal_time" />
			<ElTableColumn label="录入日期" prop="create_time" />
            <ElTableColumn  label="客户姓名" prop="customer_name" />
            <!-- <ElTableColumn label="手机号" prop="telephone" /> -->
			<ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn  label="成交类型" prop="order_type" />
			<ElTableColumn  label="业务类型" prop="business_type" />
			<ElTableColumn label="收款本金" prop="pay_amount" />
			<ElTableColumn label="系数" prop="coefficient" />
			<ElTableColumn label="收款金额" prop="totalamount" />
			<ElTableColumn label="收款类型" prop="refund_type" />
			<ElTableColumn label="收款方式" prop="refund_mode_name" />
			<ElTableColumn label="收款/退款" prop="type" />
			<ElTableColumn label="录单员" prop="operator_name" />
			 
          </el-table>
         
        </el-col>
      </el-row>
  </div>
</template>
<script>
import pagination from '@/components/Pagination'
import { readAdminNewTypeList } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: 'newsingleDetail',
   components: {
    pagination
  },
  data () {
    return {
      list: [], // 数据展示
      page: new Page(), // 分页
      type:''
    }
  },
  mounted () {
    this.type = this.$route.query.type
    //console.log('111');
    this.getList();
  },
  methods: {
    getList() {
		//console.log('222');
      readAdminNewTypeList({ ...this.page, ...this.$route.query}).then(res => {
        //console.log(res.data.list);
		this.list = res.data.list;
       /* this.list.forEach((val) => {
           val['shop_name'] = res.data.shop_name;
        }); */
        this.page.total = res.data.dataCount;
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.reveal{
  text-align: right;
  padding: 20px 0;
  .peril{
    font-weight: bold;
  }
}
.btnBox{
  text-align: right;
}
</style>
